.hero-container {
    background-color: lightblue; /* or use background-image */
    text-align: center; /* Center text within the container */
    padding: 4rem 0;  /* Adjust padding as needed */
  }

  .MuiCard-root { /* Style the Card component */
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }  

  .post-container {
  padding: 2rem; /* Add padding around the container */
  margin-left: 1rem; /* Add margin to the left side */
  margin-right: 1rem; /* Add margin to the right side */
}

.post-container h1 { /* Style the post title */
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.post-container p { /* Style the post content paragraphs */
  line-height: 1.6; /* Adjust line-height for better readability */
  margin-bottom: 1rem;
}

.post-container img {
  max-width: 100%; /* Ensure the image doesn't overflow the container */
  height: auto;     /* Maintain aspect ratio */
}

/* Header Styles */
.header-button {
  margin-left: 1rem; /* Adjust as needed */
  margin-right: 1rem;
}

/* Sign In Modal */
.signInModal {
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  width: 400px;
  background-color: white;
  border: "2px solid #000";
  padding: "16px";
}
/* styles.css */
.header-button {
  margin-left: 1rem; 
}